import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Environment } from '../../environments/environment.interface';
import { HttpClient } from '@angular/common/http';

export interface User {
    id: string;
    email: string;
}

interface AuthResponse {
    user: User;
    token: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private currentUserSubject: BehaviorSubject<User | null>;
    public currentUser: Observable<User | null>;
    private http = inject(HttpClient);

    constructor() {
        this.currentUserSubject = new BehaviorSubject<User | null>(this.getUserFromStorage());
        this.currentUser = this.currentUserSubject.asObservable();
    }

    register(email: string, password: string): Observable<AuthResponse> {
        const { apiUrl } = environment as Environment;
        return this.http.post<AuthResponse>(`${apiUrl}/auth/register`, { email, password }).pipe(
            tap(response => this.handleAuthentication(response))
        );
    }

    login(email: string, password: string): Observable<AuthResponse> {
        const { apiUrl } = environment as Environment;
        return this.http.post<AuthResponse>(`${apiUrl}/auth/login`, { email, password }).pipe(
            tap(response => this.handleAuthentication(response))
        );
    }

    logout(): void {
        const keysToRemove = ['user', 'token', 'settings'];
        keysToRemove.forEach(key => localStorage.removeItem(key));
        this.currentUserSubject.next(null);
    }

    isLoggedIn(): Observable<boolean> {
        return this.currentUser.pipe(map(user => !!user));
    }

    getToken(): string | null {
        return localStorage.getItem('token');
    }

    sendRecoverEmail(email: string): Observable<void> {
        const { apiUrl } = environment as Environment;
        return this.http.post<void>(`${apiUrl}/auth/recover`, { email });
    }

    updatePassword(password: string, token: string): Observable<void> {
        const { apiUrl } = environment as Environment;
        return this.http.put<void>(`${apiUrl}/auth/password`, { password, token });
    }

    private handleAuthentication(authResponse: AuthResponse): void {
        const { user, token } = authResponse;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
        this.currentUserSubject.next(user);
    }

    private getUserFromStorage(): User | null {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    }
}